import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

// Refs: https://github.com/aws-amplify/amplify-cli/issues/1880
// awsExports.oauth = {
//   domain: "servicenow-pdf-renderer-prod.auth.ap-southeast-2.amazoncognito.com",
//   scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
//   redirectSignIn: `${window.location.protocol}//${window.location.host}`,
//   redirectSignOut: `${window.location.protocol}//${window.location.host}`,
//   responseType: 'code',
//   label: 'Sign in with AC3 SSO'
// };

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
