import './App.css';

import logo from './logo.png';

import awsConfig from './aws-config';

import React, { useEffect, useState } from 'react';
import { API, Auth, Hub } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import S3 from 'aws-sdk/clients/s3';

import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { saveAs } from 'file-saver';


function App() {

  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        case 'customOAuthState':
          console.error(data);
          window.location = decodeURIComponent(data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);


  const path = window.location.pathname;
  const getUser = () => Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => Auth.federatedSignIn({ customState: path }));


  const [body, setBody] = useState(null);
  useEffect(() => {
    if (user) {
      if (!path.match(/^\/(?:SRQ|INC)\d+$/)) {
        setBody({ error: { msg: 'Invalid URL' } });
        return;
      }

      const getPdf = async () => {
        const credentials = await Auth.currentCredentials();
        const lambda = new Lambda({
          region: 'ap-southeast-2',
          credentials: Auth.essentialCredentials(credentials)
        });
        const lambdaParams = {
          FunctionName: awsConfig.AWS_FILE_GENERATOR_LAMBDA,
          Payload: JSON.stringify({ 
            ticketNumber: path.replace('/',''),
            userName: user.username.replace('azuread_','')
          }),
        };
        try {
          const lambdaResp = await lambda.invoke(lambdaParams).promise();
          console.log('Invoked Lambda');
          const data = JSON.parse(lambdaResp.Payload);
          console.log(data)
          if (data.error) {
            throw new Error(data.error);
          }

          const s3 = new S3({
            region: 'ap-southeast-2',
            credentials: Auth.essentialCredentials(credentials)
          });
          const s3Resp = await s3.getObject({
            Bucket: awsConfig.AWS_PDF_STORAGE_BUCKET,
            Key: data.s3objectKey
          }).promise();
          
          setBody(s3Resp.Body);

        } catch (err) {
          console.error(err);
          setBody({ error: { msg: `${err}` } });
        }
        
      }
      getPdf();
    }
  }, [user]);

  const save = () => {
    const blob = new Blob([body], { type: 'application/pdf' });
    const id = window.location.pathname.replace(/.*\//, '');
    saveAs(blob, `${id}.pdf`);
  }


  let msg;
  let type;
  if (!user) {
    msg = 'Authenticating...';
    type = 'dark';
  } else if (!body) {
    msg = 'Validating your access, Please be patient...';
    type = 'primary';
  } else if (body.error) {
    msg = `ERROR: ${body.error.msg}`;
    type = 'danger';
  }

  return (
    <div className="App">
    <div>
      <div className="page_header">
        <img src={logo} height="60px" width="auto" alt="AC3 - Clarity in the Cloud - Logo" />
      </div>
    </div>

    <div>
      <h1>AC3 ServiceNow PDF Renderer</h1>
      <p>
        Please note that PDF rendering might take some time, so be patient!
      </p>
      <p>
        If you have any feature requests or find any bugs on this site, please let us know on Slack channel <i>#ask-corp-apps-practice</i>
      </p>
    </div>

    <div >
      <div className="align-items-center">
        { msg && <div className={`alert alert-${type}`} role="alert">{msg}</div> }
        { !msg && (
          <div>
            <div className="alert alert-success" role="alert">PDF rendered successfully
            <button type="button" className="btn btn-purple btn-large margin_left" onClick={save}>Download</button>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  );
}

export default App;
